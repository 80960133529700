import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

const Calculator = ({ materials }) => {
  const [resultPrice, setPrice] = useState(0)
  const [counted, setCounted] = useState(false)
  const [touched, setTouched] = useState(false)

  const { register, handleSubmit, formState, getValues, setValue, trigger } =
    useForm({
      defaultValues: {
        bagHeight: '',
        bagWidth: '',
        bagThickness: '',
        bagAmount: '1',
      },
    })

  // Commented for the temporary solution of the trouble with Mongo on 25.01.25
  // const materialPrice = materials.length && materials[0].price

  // Temporary solution of the trouble with Mongo on 25.01.25
  const materialPrice = (materials.length && materials[0].price) || 126

  const correctFormToClosestValid = (name, value) => {
    let correctedValue = value
    const correctValue = (min, max) => {
      if (+value < min) correctedValue = min
      if (+value > max) correctedValue = max
      setValue(name, correctedValue)
    }
    if (name === 'bagHeight') {
      correctValue(40, 230)
    } else if (name === 'bagWidth') {
      correctValue(30, 110)
    } else if (name === 'bagThickness') {
      correctValue(18, 200)
    } else if (name === 'bagAmount') {
      correctValue(1, 999999999)
    }
  }

  const calculate = (price = 0) => {
    let { bagHeight, bagWidth, bagThickness, bagAmount } = getValues()

    setPrice(
      (
        (((((bagHeight / 100) * bagWidth) / 100) * bagThickness * 2 * 0.92) /
          1000) *
        price *
        bagAmount
      ).toFixed(4)
    )
  }

  const onSubmit = (data) => {
    correctFormToClosestValid('bagAmount', getValues().bagAmount)
    calculate(materialPrice)
    setCounted(true)
    setTouched(true)
  }

  const onFormChange = (event) => {
    setValue(event.target.name, +event.target.value)
    trigger([event.target.name])
    setCounted(false)
    setTouched(false)
  }

  const onBlurAction = (event) => {
    setValue(event.target.name, +event.target.value)
    correctFormToClosestValid(event.target.name, +event.target.value)
    trigger([event.target.name])
  }

  return (
    <div
      id='calculator-of-price'
      className={`section calculator-section transition ${
        formState.isValid && !touched && 'changed'
      }
       ${counted && 'counted'}
      `}
    >
      <h2>Мешки по вашим размерам</h2>
      <p>Узнать стоимость легко – введите параметры и получите расчет.</p>

      <form
        onSubmit={handleSubmit(onSubmit)}
        onChange={onFormChange}
        onBlur={onBlurAction}
      >
        <div className='container'>
          <div className='form'>
            <div className='row'>
              <div className='col-l-6 col-s-12'>
                <div className='form-row'>
                  <span className='form-label'>Высота:</span>
                  <span className='form-value'>
                    <input
                      {...register('bagHeight', {
                        required: true,
                        maxLength: 20,
                      })}
                      required
                      className='form-control'
                      type='number'
                      placeholder='40-230'
                    />
                    <span className='property-unit'>см</span>
                  </span>
                </div>
                <div className='form-row'>
                  <span className='form-label'>Ширина:</span>
                  <span className='form-value'>
                    <input
                      {...register('bagWidth', {
                        required: true,
                        maxLength: 20,
                      })}
                      required
                      className='form-control'
                      type='number'
                      placeholder='30-110'
                    />
                    <span className='property-unit'>см</span>
                  </span>
                </div>
              </div>
              <div className='col-l-6 col-s-12'>
                <div className='form-row'>
                  <span className='form-label'>Толщина:</span>
                  <span className='form-value'>
                    <input
                      {...register('bagThickness', {
                        required: true,
                        maxLength: 20,
                      })}
                      required
                      className='form-control'
                      type='number'
                      placeholder='18-200'
                    />
                    <span className='property-unit'>мкм</span>
                  </span>
                </div>
                <div className='form-row'>
                  <span className='form-label'>Количество:</span>
                  <span className='form-value'>
                    <input
                      {...register('bagAmount', {
                        required: true,
                        maxLength: 20,
                      })}
                      required
                      className='form-control'
                      type='number'
                    />
                    <span className='property-unit'>шт</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='calculated-price' id='calculated-price'>
          Стоимость =<span className='value'>{resultPrice}</span>
          <span className='unit'>₽</span>
        </div>
        <div className='buttons'>
          <button type='submit' className='button make-count'>
            Посчитать
          </button>
        </div>
      </form>
    </div>
  )
}

export default Calculator

import React, { useState } from 'react'

import imageSumka from '../img/item-sumka.jpg'
import imageMusornyiMeshok1 from '../img/item-photo-1.jpg'
import imageMusornyiMeshok2 from '../img/item-photo-2.jpg'
import imageMaika from '../img/item-maika.jpg'
import imageMaikaColor from '../img/item-maika-col.jpg'
import imageShtory from '../img/item-shtorka.jpg'
import imagePatMeshok from '../img/item-meshok-patolog.jpeg'

// Temporary solution of the trouble with Mongo on 25.01.25 => products changed to oldProducts (should be turned back)
const GoodsCatalog = ({ oldProducts, openOrderForm }) => {
  // Temporary solution of the trouble with Mongo on 25.01.25 (FAKE array below insted of broken BE, should be replaced by products coming from the string above)
  const products = [
    {
      _id: '6242d117c3153085025bc787',
      main_name: 'Мешок для мусора ПВД 30л/30шт',
      properties: '50*60см / 25мкм',
      item_image: '',
      item_price: '42-00 ₽',
      price: '42',
    },
    {
      _id: '6242d127c3153085025bc788',
      main_name: 'Мешок для мусора ПВД 60л/30шт',
      properties: '55*72см / 25мкм',
      item_image: '',
      item_price: '55-30 ₽',
      price: '55.3',
    },
    {
      _id: '6242d13cc3153085025bc789',
      main_name: 'Мешок для мусора ПВД 120л/30шт',
      properties: '67*102см / 35мкм',
      item_image: '',
      item_price: '130-20 ₽',
      price: '130.2',
    },
    {
      _id: '6242d14ac3153085025bc78a',
      main_name: 'Мешок для мусора ПВД 160л/30шт',
      properties: '80*100см / 35мкм',
      item_image: '',
      item_price: '167-70 ₽',
      price: '167.7',
    },
    {
      _id: '6242d15fc3153085025bc78b',
      main_name: 'Мешок для мусора ПВД 180л/30шт',
      properties: '85*100см / 35мкм',
      item_image: '',
      item_price: '178-10 ₽',
      price: '178.1',
    },
    {
      _id: '6242d18bc3153085025bc78d',
      main_name: 'Мешок для мусора ПВД 240л/10шт',
      properties: '92*138см / 35мкм',
      item_image: '',
      item_price: '94-20 ₽',
      price: '94.2',
    },
    {
      _id: '6242d1e4c3153085025bc792',
      main_name: 'Шторы солнцезащитные зеркальные',
      properties: '60*130см полупрозрачные/серебристые',
      item_image: '',
      item_price: '28-00 ₽',
      price: '28',
    },
  ]

  const [cropped, setCropped] = useState(true)

  const getProductImage = (product) => {
    if (product.main_name.includes('Сумка')) {
      return imageSumka
    } else if (product.main_name.includes('мусора')) {
      if (Math.random() > 0.5) return imageMusornyiMeshok1
      else return imageMusornyiMeshok2
    } else if (product.main_name.includes('Пакет-майка')) {
      if (product.main_name.includes('цветной')) return imageMaikaColor
      else return imageMaika
    } else if (product.main_name.includes('Шторы')) {
      return imageShtory
    } else {
      return imagePatMeshok
    }
  }

  return (
    <div className='mp-container'>
      <header className='mp-section-header'>
        <h2 className='mp-section-title'>Каталог продукции</h2>
        <p className='mp-section-subtitle'>
          Оставьте заявку на товар, и наш менеджер перезвонит вам для уточнения
          деталей.
        </p>
      </header>

      <div
        id='loadList'
        className={`row mp-product-list ${cropped ? 'mp-cropped' : ''}`}
      >
        {products.map((product) => (
          <div key={product._id} className='col col-l-6 col-s-12 card item'>
            <div className='item-photo'>
              <img src={getProductImage(product)} alt='product' />
            </div>
            <div className='item-description'>
              <h4>{product.main_name}</h4>
              <div className='item-properties'>{product.properties}</div>
              <div className='item-price'>
                {product.item_price}
                <span className='price-value' style={{ display: 'none' }}>
                  {product.price}
                </span>
              </div>
              <div className='control-buttons'>
                <div className='bottom-part'>
                  <div className='row'>
                    <div className='col col-l-6'>
                      <button
                        onClick={() =>
                          openOrderForm(product.main_name, 'question')
                        }
                        className='mp-button mp-button-small mp-button-block contact-button'
                      >
                        Задать вопрос
                      </button>
                    </div>
                    <div className='col col-l-6'>
                      <button
                        onClick={() => openOrderForm(product.main_name)}
                        className='mp-button mp-button-small mp-button-block order-button'
                      >
                        Заказать товар
                      </button>
                    </div>
                  </div>
                  <span className='summa mp-summa'>
                    <span className='summa-value'>{product.item_price}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className='mp-catalog-footer'>
        <span
          id='loadMore'
          className='mp-link mp-link-underline mp-load-more'
          onClick={() => setCropped(!cropped)}
        >
          {cropped ? 'Смотреть больше товара' : 'Свернуть список'}
        </span>
      </div>
    </div>
  )
}

export default GoodsCatalog

import React, { useState, useContext } from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import FormHelperText from '@mui/material/FormHelperText'
import Button from '@mui/material/Button'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { serverUrl, secretKey } from '../api/api'

import LoadingContext from '../loading.context'

const LoginForm = () => {
  const setLoading = useContext(LoadingContext)[1]

  const [formErrors, setFormErrors] = useState({
    name: '',
    password: '',
  })
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [formError, setFormError] = useState('')

  const navigate = useNavigate()

  const handleInputTyping = (e) => {
    if (e) {
      if (e.target.name === 'name') {
        setName(e.target.value)
        validateForm(e)
      } else {
        setPassword(e.target.value)
        validateForm(e)
      }
    }
    if (formError.length > 0) setFormError('')
  }

  const validateForm = (e = undefined) => {
    const validateName = () => {
      if (name.length > 30) {
        setFormErrors({ ...formErrors, name: 'Слишком длинное имя' })
      } else {
        setFormErrors({ ...formErrors, name: '' })
      }
    }

    const validatePassword = () => {
      if (password.length < 5 && password.length > 0) {
        setFormErrors({
          ...formErrors,
          password: 'Слишком короткий пароль',
        })
      } else if (name.length > 25) {
        setFormErrors({
          ...formErrors,
          password: 'Слишком длинный пароль',
        })
      } else {
        setFormErrors({ ...formErrors, password: '' })
      }
    }

    if (e) {
      if (e.target.name === 'name') {
        validateName(e)
      }
      if (e.target.name === 'password') {
        validatePassword(e)
      }
    } else {
      validateName()
      validatePassword()
    }
  }

  const sendLoginCreds = (e) => {
    if (e !== undefined) e.preventDefault()
    setLoading(true)
    axios
      .post(`${serverUrl}/login`, { user: name, password: password })
      .then((res) => {
        if (res.data + name + password === secretKey) {
          localStorage.setItem('apiKey', secretKey)
          setTimeout(() => {
            navigate('/admin-products-edit')
          }, 0)
        } else {
          setFormError('Имя и/или пароль неправильные')
        }
      })
      .catch((err) => console.log('error from Backend: ', err))
      .finally(() => setLoading(false))
  }

  return (
    <div className='page-container'>
      <Box
        component='form'
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete='off'
        onSubmit={(e) => sendLoginCreds(e)}
      >
        {/* Commented for the temporary solution of the trouble with Mongo on 25.01.25 */}
        {/* <div>
          <TextField
            onChange={(e) => handleInputTyping(e)}
            value={name}
            error={formErrors.name.length > 0}
            // focused={nameFocused}
            autoFocus={true}
            id='standard-error-helper-text-1'
            label='Имя'
            name='name'
            helperText={formErrors.name}
            variant='standard'
          />
        </div>
        <div>
          <TextField
            onChange={(e) => handleInputTyping(e)}
            value={password}
            type='password'
            error={formErrors.password.length > 0}
            id='standard-error-helper-text-2'
            label='Пароль'
            name='password'
            helperText={formErrors.password}
            variant='standard'
          />
        </div>
        <div className='buttons-centered'>
          <Button
            onClick={sendLoginCreds}
            variant='contained'
            type='submit'
            disabled={
              !name.length ||
              !password.length ||
              formErrors.name.length > 0 ||
              formErrors.password.length > 0
            }
          >
            Войти
          </Button>
        </div>
        <FormHelperText error className='form-error-below'>
          {formError}
        </FormHelperText> */}

        {/* MADE for the temporary solution of the trouble with Mongo on 25.01.25 - SHOULD be removed */}

        <h2>ВРЕМЕННО НЕ ФУНКЦИОНИРУЕТ / В РАЗРАБОТКЕ</h2>
      </Box>

      <div className='buttons-centered'>
        <Link to='/'>На Главную</Link>
      </div>
    </div>
  )
}

export default LoginForm
